









































































































































import {
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormRadio,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Entreprise } from "@/api/models/adherents/adherent";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Component, Vue, Prop } from "vue-property-decorator";
import { StatusEnum } from "@/api/models/enums/statusEnum";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";

@Component({
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormRadio,
    BButton,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
  },
})
export default class ModificationAdherent extends Vue {
  initialAdherent!: Entreprise;
  selectedAdherent: Entreprise | null = null;

  categoriesAdherent: string[] = [];
  totalCountCategoriesAdherent = 0;

  status = StatusEnum;

  async created() {
    await this.getAdherent();
    await this.loadCategoriesAdherents()
  }

  async getAdherent() {
    await this.$http.myressif.entreprises
      .getById(this.$route.params.id)
      .then((res: Entreprise) => {
        this.selectedAdherent = res;
      });
  }

  async loadCategoriesAdherents() {
    await this.$http.myressif.parametres
      .getByNom("Catégories d'Adhérents")
      .then((res: any) => {
        if (res.valeur) {
          let categories = JSON.parse(res.valeur);
          this.categoriesAdherent.push(...categories);
        }
      });
  }

  async update(selectedAdherent: Entreprise) {
    await this.$http.myressif.entreprises
      .update(this.$route.params.id, selectedAdherent)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "Modification d'un adhérent",
            text: "Modification effectuée avec succès",
          });
          this.$router.push({
            name: "details-adherent",
            params: { id: this.$route.params.id },
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  goBack() {
    this.$router.push({ name: "details-adherent" });
  }
}
